import React from 'react';
import { graphql } from 'gatsby';
import SEO from '@components/seo';
import BreadCrumb from '@components/breadcrumb';
import Camps from '@views/camps';

const RenderBody = ({ doc }) => {
  const clinics = doc.clinics;
  const winter = doc.winter_camps;
  const summer = doc.summer_camp;

  return (
    <>
      <SEO
        title="Camps"
        uri="camps"
        desc="Dynamo provides a number of fun camps and activities."
      />
      <BreadCrumb name="Camps" />
      <Camps clinics={clinics} winter={winter} summer={summer} />
    </>
  );
};

export default ({ data }) => {
  const doc = data.prismic.allCampss.edges.slice(0, 1).pop();

  if (!doc) return null;
  return <RenderBody doc={doc.node} />;
};

export const query = graphql`
  {
    prismic {
      allCampss {
        edges {
          node {
            clinics {
              image
              title
              text
            }
            winter_camps {
              image
              title
              text
            }
            summer_camp {
              image
              title
              text
            }
          }
        }
      }
    }
  }
`;
