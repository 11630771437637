import React from 'react';
import { RichText } from 'prismic-reactjs';

// styles
import { Container, NormalText } from '@styles';
import { CampsSection } from './styles';
import { Row, Title } from '../coaches/styles';
import { SpecialHeading } from '../home/classes/styles';
import { LeftCol, RightCol } from '../faq/styles';

const Camps = ({ clinics, winter, summer }) => {
  return (
    <>
      <SpecialHeading>Our clinics</SpecialHeading>
      {clinics.map((reference, i) => (
        <CampsSection key={i}>
          <Container normal>
            <Row>
              <LeftCol>
                <img
                  width={575}
                  height={380}
                  src={reference.image.url}
                  alt={reference.image.alt}
                />
              </LeftCol>
              <RightCol>
                <RichText render={reference.title} Component={Title} />
                <RichText render={reference.text} Component={NormalText} />
              </RightCol>
            </Row>
          </Container>
        </CampsSection>
      ))}
      <SpecialHeading>Our winter camps</SpecialHeading>
      {winter.map((reference, i) => (
        <CampsSection key={i}>
          <Container normal>
            <Row>
              <LeftCol>
                <img
                  width={575}
                  height={380}
                  src={reference.image.url}
                  alt={reference.image.alt}
                />
              </LeftCol>
              <RightCol>
                <RichText render={reference.title} Component={Title} />
                <RichText render={reference.text} Component={NormalText} />
              </RightCol>
            </Row>
          </Container>
        </CampsSection>
      ))}
      <SpecialHeading>Our summer camps</SpecialHeading>
      {summer.map((reference, i) => (
        <CampsSection key={i}>
          <Container normal>
            <Row>
              <LeftCol>
                <img
                  width={575}
                  height={380}
                  src={reference.image.url}
                  alt={reference.image.alt}
                />
              </LeftCol>
              <RightCol>
                <RichText render={reference.title} Component={Title} />
                <RichText render={reference.text} Component={NormalText} />
              </RightCol>
            </Row>
          </Container>
        </CampsSection>
      ))}
    </>
  );
};

export default Camps;
