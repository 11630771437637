import styled from 'styled-components';
import { theme } from '@styles';

const { colors, fonts } = theme;

export const ClassesSection = styled.section`
  width: 100%;
  height: auto;
  padding: 3rem 0;
  background-color: ${colors.hollow};

  .slick-slide {
    > div:nth-child(1) {
      margin: 0 2rem;
    }
  }

  ul {
    margin-top: 2rem !important;
  }

  li {
    button :before {
      color: ${colors.text}!important;
    }
  }

  .slick-dots {
    bottom: -35px !important;
  }
`;

export const SpecialHeading = styled.h3`
  font-family: ${fonts.Secondary};
  color: ${colors.text};
  font-size: 38px;
  font-weight: 400;
  letter-spacing: 0.08em;
  line-height: 1;
  margin-bottom: 70px;
  position: relative;
  word-wrap: break-word;
  text-align: center;

  :after {
    content: '';
    background-color: ${colors.accent};
    bottom: -50%;
    left: 50%;
    transform: translateX(-50%);
    height: 3px;
    position: absolute;
    width: 50px;
  }
`;

export const Card = styled.div`
  background: ${colors.main};
  position: relative;
`;

export const StyledImage = styled.img`
  width: 100%;
  height: 20rem;
  object-fit: cover;
`;

export const BodyWrapper = styled.div`
  padding: 1rem 1rem 0 1rem;
  text-align: center;
`;

export const BodyTitle = styled.h5`
  font-family: ${fonts.Secondary};
  color: ${colors.text};
  font-size: 24px;
  font-weight: 400;
  letter-spacing: 0.05em;
  margin-bottom: 0;
  margin-top: 6px;
  text-transform: none;
`;

export const BodyExcept = styled.p`
  margin-bottom: 0;
`;

export const FooterWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 1rem;

  svg {
    width: 1.5rem;
    color: ${colors.text};
    margin-left: 0.5rem;
  }
`;

export const FooterContent = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;

  a {
    font-family: ${fonts.Secondary};
    color: ${colors.text};
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0.05em;
    margin: 0;
    text-transform: none;

    :hover {
      color: ${colors.accent};
    }
  }
`;
